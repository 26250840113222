const icons = [
  {
    iconId: "iconArrow",
    iconSvg: `<svg viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.999427 -2.81202e-06C1.26476 5.44316e-05 1.5192 0.105502 1.70679 0.293147L9.88298 8.46933C10.8208 9.40745 11.3477 10.6796 11.3477 12.0061C11.3477 13.3326 10.8208 14.6048 9.88297 15.543L1.70679 23.7191C1.51809 23.9014 1.26536 24.0022 1.00303 24C0.740694 23.9977 0.489753 23.8925 0.30425 23.707C0.118747 23.5215 0.0135251 23.2705 0.0112458 23.0082C0.00896656 22.7458 0.109812 22.4931 0.292064 22.3044L8.46825 14.1282C9.03095 13.5654 9.34706 12.802 9.34706 12.0061C9.34706 11.2102 9.03095 10.4469 8.46825 9.88406L0.292066 1.70787C0.152185 1.56795 0.0569287 1.3897 0.0183412 1.19564C-0.0202464 1.00159 -0.000432847 0.800454 0.0752756 0.61766C0.150985 0.434867 0.27919 0.278623 0.443684 0.168683C0.608178 0.0587416 0.801575 3.91324e-05 0.999427 -2.81202e-06Z" />
    </svg>
    `,
  },
  {
    iconId: "iconArrowRight",
    iconSvg: `<svg viewBox="0 0 26 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M25.8413 8.2837L17.7163 0.158704C17.5047 -0.0529013 17.1619 -0.0529013 16.9504 0.158704C16.7388 0.370309 16.7388 0.713082 16.9504 0.924637L24.1507 8.12501H0.541683C0.242277 8.12501 0 8.36728 0 8.66669C0 8.9661 0.242277 9.20837 0.541683 9.20837H24.1507L16.9504 16.4087C16.7388 16.6203 16.7388 16.9631 16.9504 17.1746C17.0561 17.2804 17.1948 17.3333 17.3334 17.3333C17.4719 17.3333 17.6105 17.2804 17.7163 17.1746L25.8413 9.04963C26.0529 8.83808 26.0529 8.4953 25.8413 8.2837Z" />
</svg>

    `,
  },
  {
    iconId: "iconSearch",
    iconSvg: `<svg id="Outline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.02 24.02"><path d="M23.71,22.29l-6-6a10,10,0,1,0-1.42,1.42l6,6a1,1,0,0,0,1.42-1.42ZM10,18a8,8,0,1,1,8-8A8,8,0,0,1,10,18Z" transform="translate(0.03 0.03)"/></svg>`,
  },
  {
    iconId: "iconPlay",
    iconSvg: `<svg viewBox="0 0 30 37" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.843054 36.103C1.09025 36.24 1.36342 36.3081 1.63636 36.3081C1.93778 36.3081 2.23876 36.2249 2.50363 36.0594L28.6854 19.6959C29.1639 19.3969 29.4545 18.8725 29.4545 18.3082C29.4545 17.744 29.1639 17.2196 28.6854 16.9206L2.50363 0.556877C1.99909 0.241496 1.36331 0.224914 0.843054 0.513241C0.3228 0.801677 0 1.34964 0 1.94451V34.6717C0 35.2666 0.3228 35.8146 0.843054 36.103Z" />
    </svg>
    `,
  },
  {
    iconId: "iconHeadPhone",
    iconSvg: `<svg viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.0007 10.424V9C21.0007 6.61305 20.0525 4.32387 18.3646 2.63604C16.6768 0.948212 14.3876 0 12.0007 0C9.61373 0 7.32455 0.948212 5.63672 2.63604C3.94889 4.32387 3.00068 6.61305 3.00068 9V10.424C1.95184 10.8858 1.09348 11.694 0.569305 12.7131C0.0451257 13.7322 -0.113056 14.9004 0.121241 16.0222C0.355537 17.1441 0.96809 18.1513 1.85635 18.8755C2.74462 19.5996 3.85467 19.9966 5.00068 20C5.53112 20 6.03982 19.7893 6.4149 19.4142C6.78997 19.0391 7.00068 18.5304 7.00068 18V12C7.00068 11.4696 6.78997 10.9609 6.4149 10.5858C6.03982 10.2107 5.53112 10 5.00068 10V9C5.00068 7.14348 5.73818 5.36301 7.05094 4.05025C8.36369 2.7375 10.1442 2 12.0007 2C13.8572 2 15.6377 2.7375 16.9504 4.05025C18.2632 5.36301 19.0007 7.14348 19.0007 9V10C18.4702 10 17.9615 10.2107 17.5865 10.5858C17.2114 10.9609 17.0007 11.4696 17.0007 12V18H14.0007C13.7355 18 13.4811 18.1054 13.2936 18.2929C13.106 18.4804 13.0007 18.7348 13.0007 19C13.0007 19.2652 13.106 19.5196 13.2936 19.7071C13.4811 19.8946 13.7355 20 14.0007 20H19.0007C20.1467 19.9966 21.2568 19.5996 22.145 18.8755C23.0333 18.1513 23.6458 17.1441 23.8801 16.0222C24.1144 14.9004 23.9562 13.7322 23.4321 12.7131C22.9079 11.694 22.0495 10.8858 21.0007 10.424ZM5.00068 18C4.20503 18 3.44197 17.6839 2.87936 17.1213C2.31675 16.5587 2.00068 15.7956 2.00068 15C2.00068 14.2044 2.31675 13.4413 2.87936 12.8787C3.44197 12.3161 4.20503 12 5.00068 12V18ZM19.0007 18V12C19.7963 12 20.5594 12.3161 21.122 12.8787C21.6846 13.4413 22.0007 14.2044 22.0007 15C22.0007 15.7956 21.6846 16.5587 21.122 17.1213C20.5594 17.6839 19.7963 18 19.0007 18Z" />
    </svg>
    `,
  },
  {
    iconId: "iconCatalog",
    iconSvg: `<svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.6576 5.27678L24.0446 0.0509518C23.8931 -0.0169839 23.7195 -0.0169839 23.5685 0.0509518L12.1936 5.16936L0.818713 0.0509518C0.639874 -0.0291775 0.43084 -0.0135001 0.265356 0.0927584C0.0998714 0.200178 0 0.383663 0 0.580502V30.1935C0 30.4223 0.13413 30.629 0.342582 30.7231L11.9555 35.9489C12.1071 36.0168 12.2807 36.0168 12.4317 35.9489L23.8065 30.8305L35.1814 35.9489C35.2575 35.9832 35.3382 36 35.4195 36C35.5298 36 35.6396 35.9686 35.7348 35.9071C35.9003 35.7997 36.0001 35.6162 36.0001 35.4194V5.80633C36.0001 5.57756 35.866 5.37084 35.6576 5.27678ZM12.7742 6.18201L23.2259 1.47876V29.8184L12.7742 34.5217V6.18201ZM1.1613 1.47818L11.613 6.18143V34.5211L1.1613 29.8178V1.47818ZM34.8389 34.5217L24.3872 29.8184V1.47876L34.8389 6.18201V34.5217Z" />
    <path d="M10.0942 7.01223L3.12646 4.109C2.94646 4.03409 2.74207 4.05325 2.58123 4.16184C2.41923 4.26984 2.32227 4.451 2.32227 4.64493V12.1934C2.32227 12.4279 2.46336 12.6393 2.67936 12.7293L9.64713 15.6325C9.71913 15.6627 9.7952 15.6772 9.87068 15.6772C9.98333 15.6772 10.096 15.6441 10.1929 15.5797C10.3544 15.4717 10.4513 15.2905 10.4513 15.0966V7.54817C10.4513 7.31359 10.3102 7.10223 10.0942 7.01223ZM9.29004 14.2256L3.48356 11.8061V5.51591L9.29004 7.93546V14.2256Z" />
    <path d="M2.68164 15.6384L3.12874 14.5666L10.0959 17.4727L9.64883 18.5446L2.68164 15.6384Z" />
    <path d="M2.68164 18.543L3.12874 17.4711L10.0959 20.3772L9.64883 21.4491L2.68164 18.543Z" />
    <path d="M2.68164 21.4473L3.12874 20.3754L10.0959 23.2815L9.64883 24.3534L2.68164 21.4473Z" />
    <path d="M2.68164 24.3516L3.12874 23.2797L10.0959 26.1858L9.64883 27.2577L2.68164 24.3516Z" />
    <path d="M21.8062 20.4201C21.6454 20.3116 21.4404 20.2924 21.261 20.3673L14.2932 23.2705C14.0766 23.3605 13.9355 23.5719 13.9355 23.8065V31.3549C13.9355 31.5488 14.0325 31.73 14.1939 31.838C14.2909 31.9024 14.403 31.9355 14.5162 31.9355C14.5917 31.9355 14.6677 31.921 14.7397 31.8908L21.7075 28.9876C21.9235 28.8976 22.0646 28.6862 22.0646 28.4517V20.9032C22.0646 20.7093 21.9676 20.5281 21.8062 20.4201ZM20.9033 28.0644L15.0968 30.4839V24.1938L20.9033 21.7742V28.0644Z" />
    <path d="M14.2969 20.3655L21.2641 17.4593L21.7112 18.5312L14.744 21.4374L14.2969 20.3655Z" />
    <path d="M14.2949 17.4622L21.2621 14.556L21.7092 15.6279L14.742 18.534L14.2949 17.4622Z" />
    <path d="M14.2949 14.5591L21.2621 11.6529L21.7092 12.7248L14.742 15.631L14.2949 14.5591Z" />
    <path d="M14.2949 11.6558L21.2621 8.74962L21.7092 9.8215L14.742 12.7276L14.2949 11.6558Z" />
    <path d="M25.9059 12.7293L32.8737 15.6325C32.9457 15.6627 33.0218 15.6772 33.0972 15.6772C33.2099 15.6772 33.3225 15.6441 33.4195 15.5797C33.5809 15.4717 33.6779 15.2905 33.6779 15.0966V7.54817C33.6779 7.31359 33.5368 7.10223 33.3208 7.01223L26.353 4.109C26.1736 4.03409 25.9692 4.05325 25.8078 4.16184C25.6458 4.26984 25.5488 4.451 25.5488 4.64493V12.1934C25.5488 12.4279 25.6899 12.6393 25.9059 12.7293ZM26.7101 5.51591L32.5166 7.93546V14.2256L26.7101 11.8061V5.51591Z" />
    <path d="M25.916 15.6401L26.3631 14.5683L33.3303 17.4744L32.8832 18.5463L25.916 15.6401Z" />
    <path d="M25.916 18.5447L26.3631 17.4728L33.3303 20.3789L32.8832 21.4508L25.916 18.5447Z" />
    <path d="M25.916 21.449L26.3631 20.3771L33.3303 23.2832L32.8832 24.3551L25.916 21.449Z" />
    <path d="M25.916 24.3533L26.3631 23.2814L33.3303 26.1875L32.8832 27.2594L25.916 24.3533Z" />
    </svg>
    `,
  },
  {
    iconId: "iconFacebook",
    iconSvg: `<svg viewBox="0 0 9 17" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.09493 9.09969L8.5436 6.17073H5.7347V4.2718C5.7347 3.47242 6.12649 2.68883 7.38402 2.68883H8.6605V0.199056C8.6605 0.199056 7.50092 0 6.39506 0C4.08222 0 2.57193 1.40287 2.57193 3.94004V6.17073H0V9.09969H2.57193V16.1772H5.73786V9.09969H8.09493Z" />
    </svg>
    `,
  },
  {
    iconId: "iconInstagram",
    iconSvg: `<svg viewBox="0 0 17 17"  xmlns="http://www.w3.org/2000/svg">
    <path d="M8.09312 3.94142C5.79705 3.94142 3.94503 5.79344 3.94503 8.08952C3.94503 10.3856 5.79705 12.2376 8.09312 12.2376C10.3892 12.2376 12.2412 10.3856 12.2412 8.08952C12.2412 5.79344 10.3892 3.94142 8.09312 3.94142ZM8.09312 10.7863C6.60934 10.7863 5.39632 9.57691 5.39632 8.08952C5.39632 6.60212 6.60573 5.39271 8.09312 5.39271C9.58052 5.39271 10.7899 6.60212 10.7899 8.08952C10.7899 9.57691 9.57691 10.7863 8.09312 10.7863ZM13.3784 3.77174C13.3784 4.30965 12.9452 4.73927 12.4109 4.73927C11.873 4.73927 11.4434 4.30604 11.4434 3.77174C11.4434 3.23743 11.8766 2.80421 12.4109 2.80421C12.9452 2.80421 13.3784 3.23743 13.3784 3.77174ZM16.1258 4.75371C16.0644 3.45765 15.7684 2.30961 14.8189 1.36375C13.873 0.417878 12.725 0.121844 11.4289 0.0568604C10.0932 -0.0189535 6.08947 -0.0189535 4.75371 0.0568604C3.46126 0.118233 2.31322 0.414268 1.36375 1.36014C0.414268 2.306 0.121844 3.45404 0.0568604 4.7501C-0.0189535 6.08586 -0.0189535 10.0896 0.0568604 11.4253C0.118233 12.7214 0.414268 13.8694 1.36375 14.8153C2.31322 15.7612 3.45765 16.0572 4.75371 16.1222C6.08947 16.198 10.0932 16.198 11.4289 16.1222C12.725 16.0608 13.873 15.7648 14.8189 14.8153C15.7648 13.8694 16.0608 12.7214 16.1258 11.4253C16.2016 10.0896 16.2016 6.08947 16.1258 4.75371ZM14.4001 12.8586C14.1185 13.5662 13.5734 14.1113 12.8622 14.3965C11.7972 14.8189 9.27004 14.7214 8.09312 14.7214C6.91621 14.7214 4.38547 14.8153 3.32407 14.3965C2.61648 14.1149 2.07134 13.5698 1.78614 12.8586C1.36375 11.7936 1.46122 9.26643 1.46122 8.08952C1.46122 6.9126 1.36736 4.38186 1.78614 3.32046C2.06773 2.61287 2.61287 2.06773 3.32407 1.78253C4.38908 1.36014 6.91621 1.45761 8.09312 1.45761C9.27004 1.45761 11.8008 1.36375 12.8622 1.78253C13.5698 2.06412 14.1149 2.60926 14.4001 3.32046C14.8225 4.38547 14.725 6.9126 14.725 8.08952C14.725 9.26643 14.8225 11.7972 14.4001 12.8586Z"/>
    </svg>
    `,
  },
  {
    iconId: "iconLinkedIn",
    iconSvg: `<svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.62182 16.1772H0.267213V5.37676H3.62182V16.1772ZM1.94271 3.90348C0.870248 3.90348 0 3.01517 0 1.94271C0 0.870248 0.870248 0 1.94271 0C3.01517 0 3.88542 0.870248 3.88542 1.94271C3.88542 3.01517 3.01517 3.90348 1.94271 3.90348ZM16.1736 16.1772H12.8262V10.9196C12.8262 9.66661 12.801 8.05972 11.0821 8.05972C9.33801 8.05972 9.0708 9.42107 9.0708 10.8294V16.1772H5.7198V5.37676H8.93719V6.85004H8.98414C9.4319 6.00146 10.526 5.10594 12.1582 5.10594C15.5525 5.10594 16.1772 7.34114 16.1772 10.2444V16.1772H16.1736Z" />
    </svg>
    `,
  },
  {
    iconId: "iconYoutube",
    iconSvg: `<svg viewBox="0 0 24 17" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.6279 2.5319C22.3613 1.53347 21.5784 0.749882 20.5839 0.484474C18.7811 0 11.5573 0 11.5573 0C11.5573 0 4.32922 0 2.52644 0.484474C1.53194 0.749882 0.749044 1.53347 0.482435 2.5319C0 4.33499 0 8.10546 0 8.10546C0 8.10546 0 11.8717 0.482435 13.679C0.749044 14.6732 1.53194 15.4273 2.52644 15.6928C4.32922 16.1772 11.5573 16.1772 11.5573 16.1772C11.5573 16.1772 18.7853 16.1772 20.5881 15.6928C21.5826 15.4273 22.3655 14.6732 22.6321 13.679C23.1146 11.8717 23.1146 8.10546 23.1146 8.10546C23.1146 8.10546 23.1103 4.33499 22.6279 2.5319ZM9.19165 11.5263V4.68465L15.2348 8.10546L9.19165 11.5263Z" />
    </svg>    
    `,
  },
];

export default icons;
